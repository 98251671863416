import  React from "react"
import Layout from "../../components/layout"
import { Link } from 'gatsby'
import Head from '../../components/head'
import LatQuotesIntro from './LatQuotesIntro'

// markup
const LatQuotes_c = () => {
  return (
   <Layout >
      <Head title='Latin Quotes and Expressions - C'/>
     <h1>LATIN QUOTES AND EXPRESSIONS</h1>
     <h2>C</h2>
     <LatQuotesIntro />
    
<p><b>Cacoethes scribendi</b> - An insatiable urge to write. (Juvenal)</p>
<p><b>Cadit quaestio</b> - The question drops</p>
<p><b>Caeca invidia est</b> - Envy is blind. (Livy)</p>
<p><b>Caeci caecos ducentes</b> - Blind leading the blind</p>
<p><b>Caeli enarrant gloriam Dei</b> - The heavens declare the glory of God</p>
<p><b>Caelum non animum mutant qui trans mare currunt</b> - They change the sky, not their soul, who run across the sea. (Horace)</p>
<p><b>Caelum videre iussit, et erectos ad sidera tollere vultus</b> - He bid them look at the sky and lift their faces to the stars. (Ovid)</p>
<p><b>Caesar si viveret, ad remum dareris</b> - If Caesar were alive, you'd be chained to an oar</p>
<p><b>Camera obscvra</b> - Hidden room</p>
<p><b>Canis timidus vehementius latrat quam mordet</b> - A timid dog barks more violently than it bites. (Curtius Rufus)</p>
<p><b>Carpe diem</b> - Seize the day. (opportunity) (Horace)</p>
<p><b>Carpe diem, quam minimum credula postero</b> - Seize the day, trust as little as possible in tomorrow. (Horace)</p>
<p><b>Casus belli</b> - Event (that is the justification for, or the cause) of war</p>
<p><b>Causa mortis</b> - Death Cause</p>
<p><b>Causarum justia et misericordia</b> - For the causes of justice and mercy</p>
<p><b>Cave ab homine unius libri</b> - Beware of anyone who has just one book. (Latin Epigram)</p>
<p><b>Cave canem</b> - Beware of the dog</p>
<p><b>Cave cibum, valde malus est</b> - Beware the food, it is very bad</p>
<p><b>Cave quid dicis, quando, et cui</b> - Beware what you say, when, and to whom</p>
<p><b>Cave</b> - Beware!</p>
<p><b>Caveat emptor</b> - Let the buyer beware. (He buys at his own risk)</p>
<p><b>Caveat venditor</b> - Let the seller beware</p>
<p><b>Caveat</b> - Let him/her beware</p>
<p><b>Cedant arma togae</b> - Let arms yield to the toga. (Let violence give place to law)</p>
<p><b>Cedo maiori</b> - I yield to a greater person</p>
<p><b>Certum est, quia impossibile</b> - It is certain, because it is impossible. (Tertullianus)</p>
<p><b>Cetera desunt</b> - The rest is missing</p>
<p><b>Ceteris paribus</b> - All else being equal</p>
<p><b>Christus rex</b> - Christ the King</p>
<p><b>Cineri gloria sera venit</b> - Fame comes too late to the dead</p>
<p><b>Circa (c.)</b> - Approximately</p>
<p><b>Clara pacta, boni amici</b> - Clear agreements, good friends</p>
<p><b>Codex Juris Canonici</b> - Book of canon law</p>
<p><b>Cogito, ergo sum</b> - I think, therefore I am. (Reni Descartes)</p>
<p><b>Commodum ex iniuria sua nemo habere debet</b> - No person ought to have advantage from his own wrong</p>
<p><b>Commune bonum</b> - The common good</p>
<p><b>Commune periculum concordiam parit</b> - Common danger brings forth harmony</p>
<p><b>Communi consilio</b> - By common consent</p>
<p><b>Compos mentis</b> - Of sound mind (and judgement)</p>
<p><b>Concordia discors</b> - Discordant harmony</p>
<p><b>Concordia res parvae crescent</b> - Work together to accomplish more</p>
<p><b>Conditio sine qua non</b> - Condition without which not, or an essential condition or requirement</p>
<p><b>Confer (cf.)</b> - Compare</p>
<p><b>Confiteor</b> - I confess</p>
<p><b>Congregatio de Propaganda Fide</b> - Congregation for the Propagation of the Faith</p>
<p><b>Coniecturalem artem esse medicinam</b> - Medicine is the art of guessing. (Aulus Cornelius Celsus)</p>
<p><b>Coniunctis viribus</b> - With united powers</p>
<p><b>Conlige suspectos semper habitos</b> - Round up the usual suspects</p>
<p><b>Consensu omnium</b> - By the agreement of all</p>
<p><b>Consensus audacium</b> - An agreement of rash men. (a conspiracy) (Cicero)</p>
<p><b>Consuetudinis magna vis est</b> - The force of habit is great. (Cicero)</p>
<p><b>Consule Planco</b> - In the consulship of Plancus (In the good old days) (Horace)</p>
<p><b>Consummatum est</b> - It is completed (Christ's last words, John 19:30)</p>
<p><b>Contra felicem vix deus vires habet</b> - Against a lucky man a god scarcely has power</p>
<p><b>Contra mundum</b> - Against the world</p>
<p><b>Contraria contrariis curantur</b> - The opposite is cured with the opposite. (Hippocrates)</p>
<p><b>Coram populo</b> - In the presence of the people. (Horace)</p>
<p><b>Cornix cornici oculos non effodiet</b> - A crow doesn't rip out the eyes of another crow</p>
<p><b>Cornucopia</b> - Horn of plenty</p>
<p><b>Corpus christi</b> - The body of Christ</p>
<p><b>Corpus delicti</b> - The body of a crime. (The substance or fundamental facts of a crime)</p>
<p><b>Corpus Juris Canonici</b> - The body of canon law</p>
<p><b>Corpus Juris Civilis</b> - The body of civil law</p>
<p><b>Corpus vile</b> - Worthless body</p>
<p><b>Corrigenda</b> - A list of things to be corrected. (in a book)</p>
<p><b>Corruptio optimi pessima</b> - Corruption of the best is worst</p>
<p><b>Cotidiana vilescunt</b> - Familiarity breeds contempt</p>
<p><b>Cotidie damnatur qui semper timet</b> - The man who is constantly in fear is every day condemned. (Syrus)</p>
<p><b>Cras amet qui nunquam amavit; Quique amavit, cras amet</b> - May he love tomorrow who has never loved before</p>
<p><b>Credite amori vera dicenti</b> - Believe love speaking the truth. (St. Jerome)</p>
<p><b>Credo quia absurdum</b> - I believe it because it is absurd. (contrary to reason) (Tertullian)</p>
<p><b>Credo ut intelligam</b> - I believe in order that I may understand. (St. Augustine)</p>
<p><b>Credula vitam spes fovet et melius cras fore semper dicit</b> - Credulous hope supports our life, and always says that tomorrow will be better. (Tibullus)</p>
<p><b>Crescit amor nummi, quantum ipsa pecunia crevit</b> - The love of wealth grows as the wealth itself grew. (Juvenalis)</p>
<p><b>Crescite et multiplicamini</b> - Increase and multiply</p>
<p><b>Crimen falsi</b> - Perjury</p>
<p><b>Crudelius est quam mori semper timere mortem</b> - It is more cruel to always fear death than to die. (Seneca)</p>
<p><b>Crux</b> - Puzzle</p>
<p><b>Cui bono?</b> - For whose benefit is it? (a maxim sometimes used in the detection of crime) (Cicero)</p>
<p><b>Cui dono lepidum novum libellum?</b> - To whom do I give my new elegant little book? (Catullus)</p>
<p><b>Cui malo?</b> - Who suffers a detriment?</p>
<p><b>Cui peccare licet peccat minus</b> - One who is allowed to sin, sins less. (Ovid)</p>
<p><b>Cuius regio, eius religio</b> - He who rules, his religion</p>
<p><b>Cuiusvis hominis est errare; nullius nisi insipientis in errore perseverare</b> - Any man can make a mistake; only a fool keeps making the same one</p>
<p><b>Cuivis dolori remedium est patientia</b> - Patience is the cure for all suffer</p>
<p><b>Culpa</b> - A sin</p>
<p><b>Culpam poena premit comes</b> - Punishment closely follows crime as its companion. (Horace)</p>
<p><b>Cum catapultae proscriptae erunt tum soli proscript catapultas habebunt</b> - When catapults are outlawed, only outlaws will have catapults</p>
<p><b>Cum grano salis</b> - With a grain of salt. (Pliny the Elder?)</p>
<p><b>Cum laude magnum</b> - With great success</p>
<p><b>Cum laude</b> - With praise</p>
<p><b>Cum tacent, clamant</b> - When they remain silent, they cry out. (Their silence speaks louder than words) (Cicero)</p>
<p><b>Cum</b> - With</p>
<p><b>Cura nihil aliud nisi ut valeas</b> - Pay attention to nothing except that you do well. (Cicero)</p>
<p><b>Cura posterior</b> - A later concern</p>
<p><b>Cura ut valeas</b> - Take care</p>
<p><b>Curae leves loquuntur ingentes stupent</b> - Slight griefs talk, great ones are speechless. (minor losses can be talked away, profound ones strike us dumb)</p>
<p><b>Curriculum vitae</b> - The course of one's life</p>
<p><b>Cursum perficio</b> - My journey is over, or I finish my journey</p>
<p><b>Custos morum</b> - Guardian of morals</p>


   </Layout>
  )
}

export default LatQuotes_c
